@use "../styles/common"

.wrapperTrends
	@media (min-width: common.$break768)
		margin-top: 3rem

.wrapperAllArticles, .wrapperSocials
	padding-top: 80px //padding in order to offset scroll

.wrapperTips
	margin-top: 80px

.wrapperYourStories
	margin-top: 1.5rem
	border-top: 1px solid common.$c-light-grey
	@media (min-width: common.$break768)
		margin-top: 3rem
		border-top: 0
