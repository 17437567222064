@use 'common'

.Container

	&.large
		font-size: 1.2em
		font-size: clamp(1.1em, 2vw, 2em)

.Items
	display: flex
	flex-direction: row
	display: flex
	align-items: center
	justify-content: center
	flex-wrap: wrap
	font-size: 1.2rem
	@media(min-width: common.$break480)
		font-size: 1.6rem

.Item
	margin: 0.4rem
	@media(min-width: common.$break480)
		margin: .5em
		margin-right: 1em

.button
	text-align: center
	margin-bottom: 1.5rem
	margin-top: 1rem
	@media (min-width: common.$break768)
		margin-top: 4rem
