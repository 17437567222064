@use "common"

.wrapper
	overflow: hidden

.headline
	// margin: 0
	// margin-left: 10px
	// font-size: 50px
	// position: relative
	// z-index: 1
	// font-weight: 900

	// @media (min-width: common.$break1000)
	// 	margin-left: 30px
	// 	font-size: 60px
