@use './common'

.wrapper
	display: grid
	background-color: var(--loop-bgColor)
	margin-block: 1rem 1.5rem
	z-index: 1

@keyframes slideAnimation
	from
		transform: translateX(0%)

	to
		transform: translateX(-100%)

.looper
	position: relative
	width: 100%
	overflow: hidden
	display: flex
	justify-content: center
	width: fit-content
	max-width: calc(var(--loop-maxWidth) - 5%)
	&::after
		content: '*'
		color: var(--loop-textColor)
		padding-top: 6px
		font-size: 1.2rem
		margin-left: 0.5rem
		margin-right: 0.5rem

.children
	text-transform: uppercase
	letter-spacing: 3px
	padding: 7px 0.2rem
	font-size: 1.2rem
	display: block
	color: var(--loop-textColor)
