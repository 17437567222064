@use 'common'

$background: #F4F4F4

.wrapper
	background: $background
	padding: 3rem 0 2rem 0
	text-align: center

.headline
	max-width: 300px
	margin: 0 auto
	font-size: 60px
	font-weight: 900
	font-family: common.$f-montreal

	@media (min-width: common.$break1000)
		text-align: left

.tags
	margin-top: 36px

	@media (min-width: common.$break1000)
		.tags
			margin: 0
			flex-basis: 50%

.callToAction
	position: relative
	margin-inline: auto
	@media (min-width: common.$break768)
		max-width: 40%
		margin: 100px auto 50px auto

	&Headline
		font-size: 60px
		margin-bottom: 0.5rem
		margin-top: 0
		z-index: 1
		position: relative
		font-weight: 900

	&Text
		font-size: 2rem
		font-weight: 600
		margin: 0
		z-index: 1
		position: relative
		background-color: #000
		color: #fff
		width: fit-content
		margin: auto
		padding: 0.7rem 4.5rem
		border-radius: 3.4375rem

	@media (min-width: common.$break1000)
		margin: 0

	&Button
		margin-top: 20px
		z-index: 1
		position: relative

@media (min-width: common.$break1000)
	.items
		display: flex
		align-items: flex-start
		justify-content: stretch

	.item
		flex-basis: 40%
	.item:first-child
		margin: 0
		margin-right: auto
	.item:last-child
		margin-left: auto
	.tags
		margin-top: 0
		h2
			margin-top: 0
