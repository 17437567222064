@use 'common'

$gridColumnsTablet: 2
$gridColumnsDesktop: 3

.wrapper
	display: grid
	grid-template-rows: repeat(var(--Trends-mobile-grid-rows), auto)
	gap: 20px
	align-items: start

	@media (min-width: common.$break480)
		grid-template-columns: repeat($gridColumnsTablet, 1fr)
		grid-template-rows: initial

	@media (min-width: common.$break1000)
		grid-template-columns: repeat($gridColumnsDesktop, 1fr)
		grid-template-rows: repeat(var(--Trends-desktop-grid-rows), auto)

.headline
	@media (min-width: common.$break480)
		grid-column: 1 / -1

.tile
	//

// .messageTile

// 	@media (min-width: common.$break480)
// 		grid-column: span $gridColumnsTablet

// 	@media (min-width: common.$break700)
// 		grid-column: 2
// 		grid-row: 2

// 	@media (min-width: common.$break1000)
// 		grid-column: $gridColumnsDesktop
// 		grid-row: 1 / 3

// .binaryPoll
// 	display: flex
// 	justify-content: center

// 	@media (min-width: common.$break480)
// 		grid-column: span $gridColumnsTablet

// 	@media (min-width: common.$break1000)
// 		grid-column: span 1

// 	@media (max-width: common.$breakBinaryPollVisibilityMax)
// 		display: none

.button
	text-align: center
	align-self: center
	grid-column: 1 / -1
	@media (min-width: common.$break768)
		margin-top: 2rem

	// @media (min-width: common.$break480)
	// 	grid-column: span $gridColumnsTablet

	// @media (min-width: common.$break1000)
	// 	grid-column: span ($gridColumnsDesktop - 1)
